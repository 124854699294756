import Vue from 'vue'
import Router from 'vue-router'
import index from '../components/index.vue'
import axios from 'axios'
import Cookies from 'js-cookie'


//添加以下代码
Vue.prototype.$isAdmin = false
Vue.prototype.$isCommon = false

Vue.use(Router)

const router = new Router({
  routes: [{
    path: '/',
    redirect: '/index',
    component: () => import("../components/index.vue")
  }, {
    path: '/index',
    component: () => import("../components/index.vue")
  }]
})

export default router
