import Vue from 'vue'
import {
  Message,
  Button,
  Form,
  FormItem,
  Input,
  Link,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Menu,
  MenuItem,
  Submenu,
  Row,
  Col,
  Collapse,
  CollapseItem,
  TimeSelect,
  Select,
  Option,
  DatePicker,
  Table,
  TableColumn,
  Popover,
  Pagination,
  Tag,
  Dialog,
  Radio,
  RadioGroup,
  Descriptions,
  DescriptionsItem,
  Tooltip,
  InputNumber,
  MessageBox,
  Loading,
  Switch,
  Result,
  Notification,
  RadioButton,
  Image,
  Checkbox,
  Divider,
  Tabs,
  TabPane,
  MenuItemGroup,
  TimePicker,
  Breadcrumb,
  BreadcrumbItem,
  Carousel,
  CarouselItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Badge,
  Statistic
} from 'element-ui'
Vue.use(Statistic)
Vue.use(Badge)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(BreadcrumbItem)
Vue.use(Breadcrumb)
Vue.use(TimePicker)
Vue.use(MenuItemGroup)
Vue.use(TabPane)
Vue.use(Divider)
Vue.use(Tabs)
Vue.use(Checkbox)
Vue.use(Image)
Vue.use(RadioButton)
Vue.component(Notification)
Vue.use(Result)
Vue.use(Switch)
Vue.use(Loading)
Vue.use(InputNumber)
Vue.use(Tooltip)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Link)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Row)
Vue.use(Col)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(TimeSelect)
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Popover)
Vue.use(Pagination)
Vue.use(Tag)
Vue.use(Dialog)
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification
Vue.prototype.$confirm = MessageBox.confirm
